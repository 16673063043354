import { create } from "zustand";

import { Nullable } from "@Shared/types";
import { createSelectors, scrollLock } from "@Shared/utils";

const getScrollLockName = (id: string) => `modal-${id}`;

type ModalModel = {
    isOpen: boolean;
    data: Nullable<Record<string, unknown>>;
};

interface State {
    activeModals: Record<string, ModalModel>;
}

interface Action {
    create: (id: string) => void;
    open: (id: string, data?: ModalModel["data"]) => void;
    close: (id: string) => void;
    isOpen: (id: string) => boolean;
    getData: (id: string) => ModalModel["data"];
}

const defaultValue: State = {
    activeModals: {},
};

const useModalStateBase = create<State & Action>()((set, get) => ({
    ...defaultValue,

    create: id => {
        set(state =>
            state.activeModals[id]
                ? state
                : { activeModals: { ...state.activeModals, [id]: { isOpen: false, data: null } } }
        );
    },

    open: (id, data) => {
        if (!get().activeModals[id]) {
            return;
        }

        set(state => ({
            activeModals: {
                ...state.activeModals,
                [id]: { isOpen: true, data: typeof data !== "undefined" ? data : state.activeModals[id].data },
            },
        }));

        scrollLock(true, getScrollLockName(id));
    },

    close: id => {
        if (!get().activeModals[id]) {
            return;
        }

        set(state => ({ activeModals: { ...state.activeModals, [id]: { ...state.activeModals[id], isOpen: false } } }));

        scrollLock(false, getScrollLockName(id));
    },

    isOpen: id => get().activeModals[id]?.isOpen,

    getData: id => get().activeModals[id]?.data,
}));

export const useModalState = createSelectors(useModalStateBase);
