import { useEffect, useState } from "react";
import { useWindowSize } from "react-use";

import { IS_CLIENT } from "@Shared/config";
import { twTheme } from "@Shared/utils";

type CurrentScreensStateModel = {
    isSm: boolean;
    isMd: boolean;
    isLg: boolean;
    isXl: boolean;
    is2xl: boolean;
    is3xl: boolean;
};

const getCurrentScreensState = (): CurrentScreensStateModel => {
    return {
        isSm: IS_CLIENT && innerWidth >= parseInt(twTheme.screens.sm, 10),
        isMd: IS_CLIENT && innerWidth >= parseInt(twTheme.screens.md, 10),
        isLg: IS_CLIENT && innerWidth >= parseInt(twTheme.screens.lg, 10),
        isXl: IS_CLIENT && innerWidth >= parseInt(twTheme.screens.xl, 10),
        is2xl: IS_CLIENT && innerWidth >= parseInt(twTheme.screens["2xl"], 10),
        is3xl: IS_CLIENT && innerWidth >= parseInt(twTheme.screens["3xl" as "2xl"], 10),
    };
};

export const useScreens = () => {
    const [screensState, setScreensState] = useState<CurrentScreensStateModel>(getCurrentScreensState());

    const { width } = useWindowSize();

    useEffect(() => {
        setScreensState(getCurrentScreensState());
    }, [width]);

    return screensState;
};
