"use client";

import { initReactI18next } from "react-i18next";
import i18next from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";

import { IS_SSR } from "@Shared/config";

import { DEFAULT_LANGUAGE, getOptions } from "./config";

export { useTranslation } from "react-i18next";

i18next
    .use(initReactI18next)
    .use(resourcesToBackend((language: string, namespace: string) => import(`@/locales/${language}/${namespace}.json`)))
    .init({
        ...getOptions(),
        interpolation: {
            escapeValue: false,
        },
        preload: IS_SSR ? [DEFAULT_LANGUAGE] : [],
    });

// export function useTranslation(
//     ns?: string,
// ) {
//     return useTranslationOrg(ns, opts);
//     // todo: language selection
// }
