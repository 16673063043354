"use client";

import { useEffect, useState } from "react";
import useScrollbarSize from "react-scrollbar-size";
import { usePathname } from "next/navigation";

const setProps = (value: number) => {
    document.documentElement.style.setProperty("--scrollbar-width", `${value}px`);
    document.documentElement.classList.toggle("no-scrollbar", !value);
};

export const useSetScrollbarWidth = () => {
    const [bodyScrollHeight, setBodyScrollHeight] = useState<number>(0);

    const pathname = usePathname();

    const { width } = useScrollbarSize();

    useEffect(() => {
        setProps(bodyScrollHeight > innerHeight ? width : 0);
    }, [bodyScrollHeight, pathname, width]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setBodyScrollHeight(document.body.scrollHeight);
        });

        resizeObserver.observe(document.body);

        return () => resizeObserver.disconnect();
    }, []);
};
